import RelatedGroupsApi, { RelatedGroupAPI, RelatedGroupRaw, RelatedGroupResponse } from '@/API/relatedGroupsApi';
import ProductAPI, { ProductsRelatedGroupsResponse } from '@/API/productApi';
import { GroupRequest } from '@/models/group/group';
import useLiveProductPrices, { LiveProductPrices } from '@/composables/useLiveProductPrices';
import { Commit } from 'vuex';
import { ProductRelatedGroup, RelatedGroup, RelatedGroups, RelatedGroupsState } from '@/models/relatedGroup/relatedGroup';
import { ref } from 'vue';
const { getLivePricesForProducts } = useLiveProductPrices();

const state = (): RelatedGroupsState => ({
  relatedProducts: [],
});

export interface relationGroupPayload {
  productID: string;
  shopID?: string;
}
function removeVariants(input: RelatedGroupResponse): RelatedGroupRaw {
  const cleanedResponse = ref<RelatedGroupRaw>({
    RelatedGroups: [],
  });

  input.data.RelatedGroups.forEach((group: RelatedGroupAPI) => {
    if (group.Products) {
      const productsWithEmptyVariantId = group.Products.filter((product) => product.VariantId === '');

      if (productsWithEmptyVariantId.length > 0) {
        cleanedResponse.value.RelatedGroups.push({
          ...group,
          Products: productsWithEmptyVariantId,
        });
      }
    }
  });

  return cleanedResponse.value;
}
const actions = {
  async relationGroups({ commit }: { commit: Commit }, payload: relationGroupPayload): Promise<RelatedGroups | undefined> {
    try {
      const shopID = payload.shopID || 'SHOP1';
      const params: GroupRequest = {
        ShopId: shopID,
        CountryCode: 'IS',
        CurrencyCode: 'ISK',
        FilledProperties: 'RelatedGroups',
      };

      const relatedGroupResponse: RelatedGroupResponse = await RelatedGroupsApi.getRelatedGroups(payload.productID, params);
      const cleanedRelatedGroupResponse: RelatedGroupRaw = removeVariants(relatedGroupResponse);
      const productIds = extractProductIds(cleanedRelatedGroupResponse.RelatedGroups);

      if (cleanedRelatedGroupResponse && productIds.length > 0) {
        const productsResponse: ProductsRelatedGroupsResponse = await ProductAPI.getProductsById(productIds, shopID);

        const livePrices: LiveProductPrices = await getLivePricesForProducts(productIds);

        const result = createRelatedGroupProducts(payload.productID, productsResponse.data.Products, cleanedRelatedGroupResponse.RelatedGroups, livePrices);

        commit('setRelatedProduct', result);
        return result;
      }
      return undefined;
    } catch (error) {
      console.error('Error fetching related groups:', error);
      throw error;
    }
  },
};
function extractProductIds(relatedGroup: RelatedGroupAPI[]): string[] {
  const productIds: string[] = [];
  if (Array.isArray(relatedGroup)) {
    relatedGroup.forEach((group: RelatedGroupAPI) => {
      if (Array.isArray(group.Products)) {
        group.Products.forEach((product) => {
          productIds.push(product.ProductId);
        });
      }
    });
  }
  return productIds;
}

function createRelatedGroupProducts(
  productId: string,
  productResponse: ProductRelatedGroup[],
  relatedGroupsResponse: RelatedGroupAPI[],
  livePrices: LiveProductPrices,
): RelatedGroups {
  const products = Array.isArray(productResponse) ? productResponse : [];

  // Map products back to their respective groups with live prices
  const relatedGroups = relatedGroupsResponse.map((group) => {
    const enrichedProducts: ProductRelatedGroup[] = products
      .filter((product) => group.Products?.some((p) => p.ProductId === product.Id))
      .map((product) => {
        const livePrice = livePrices[product.Id];
        return {
          ...product,
          Price: livePrice?.Price,
          PriceBeforeDiscount: livePrice?.PriceBeforeDiscount,
        };
      });

    return {
      GroupID: group.Id,
      Name: group.Name,
      Products: enrichedProducts,
    };
  });

  const result: RelatedGroups = {
    productID: productId,
    relatedProducts: relatedGroups,
  };
  return result;
}

const mutations = {
  setRelatedProduct(state: RelatedGroupsState, relatedData: RelatedGroups): void {
    const existingIndex = state.relatedProducts.findIndex((rp) => rp.productID === relatedData.productID);
    if (existingIndex !== -1) {
      state.relatedProducts[existingIndex] = relatedData;
    } else {
      state.relatedProducts.push(relatedData);
    }
  },
};

const getters = {
  getRelatedGroups(state: RelatedGroupsState): RelatedGroups[] {
    return state.relatedProducts;
  },

  getRelatedGroupByName:
    (state: RelatedGroupsState) =>
    (productId: string, groupName: string): RelatedGroup | undefined => {
      for (const relatedGroup of state.relatedProducts) {
        if (relatedGroup.productID === productId) {
          const foundGroup = relatedGroup.relatedProducts.find((group) => group.Name === groupName);
          if (foundGroup) {
            return foundGroup;
          }
        }
      }
      return undefined;
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
