import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23feb5da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "related-product-carousel__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.hasMounted)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.productList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.props.heading), 1 /* TEXT */),
                _createVNode($setup["ProductCarouselRender"], {
                  "product-list": $setup.productList,
                  "add-to-cart": $setup.addToCart,
                  area: $setup.productArea
                }, null, 8 /* PROPS */, ["product-list", "add-to-cart", "area"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}