
  import { computed, defineComponent, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  import useCart from '@/composables/useCart';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import { OrderLine } from '@/models/cart/orderLine';

  import MobileTabletBurger from '@/global_components/navigation/mobile_tablet/MobileTabletBurger.vue';
  import CartOverviewOrderLines from '@/components/cart/checkout/overview/CartOverviewOrderLines.vue';
  import CartOverviewSideInfo from '@/components/cart/checkout/overview/CartOverviewSideInfo.vue';
  import CartOverviewOrderLineModal from '@/components/cart/checkout/overview/CartOverviewOrderLineModal.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';
  import { GA4E } from '@/ga4ecommerce/GA4E';
  import { getSideInfoItems } from './getSideInfoItems';
  import RelatedProductCarousel from "@/components/carousel/RelatedProductCarousel/RelatedProductsCarousel.vue";

  export default defineComponent({
    components: {
      CartOverviewOrderLines,
      CartOverviewSideInfo,
      MobileTabletBurger,
      Loader,
      CartOverviewOrderLineModal,
      CustomButton,
      RelatedProductCarousel,
    },
    setup() {
      const router = useRouter();
      const { websiteSettings } = useWebsiteSettings();
      const recalculating = ref(false);
      const couponRecalculating = ref(false);
      const updatingBeforeNextStep = ref(false);
      const showModal = ref(false);
      const selectedOrderLine = ref({});
      const isUpdateCoupon = false;

      const {
        orderLines,
        formattedTotalAmountToBePaid,
        totalQuantity,
        thereAreOrderLinesToBeUpdated,
        recalculateCart,
        getCouponCode,
        getCouponOfferValid,
        setCouponCode,
        giftWrapProduct,
      } = useCart();

      const burgerClick = () => {
        if (!showModal.value) {
          router.back();
        } else {
          showModal.value = !showModal.value;
        }
      };
      const couponInput = ref(getCouponCode.value);
      const updateCoupon = () => {
        setCouponCode(couponInput.value);
      };

      const openDetailModal = (orderLine: OrderLine) => {
        selectedOrderLine.value = orderLine;
        showModal.value = true;
      };

      const closeModal = () => {
        showModal.value = false;
      };

      const recalculate = async (origin) => {
        if (origin === 'cartRecalculate') {
          recalculating.value = true;
        } else if (origin === 'couponCode') {
          couponRecalculating.value = true;
        }
        await recalculateCart();
        if (origin === 'cartRecalculate') {
          recalculating.value = false;
        } else if (origin === 'couponCode') {
          couponRecalculating.value = false;
        }
      };

      const goToNextStep = async () => {
        if (thereAreOrderLinesToBeUpdated.value) {
          updatingBeforeNextStep.value = true;
          await recalculateCart();
          router.push('/cart/contact-info');
          updatingBeforeNextStep.value = false;
        } else {
          router.push('/cart/contact-info');
        }

        GA4E.beginCheckout(orderLines.value);
      };

      GA4E.viewCart(orderLines.value);

      const sideInfoItems = computed(() => {
        return getSideInfoItems(orderLines.value);
      });

      const cartProductsMinHeight = computed(() => {
        return `${Math.max(sideInfoItems.value.length - 1, 1) * 150 + 4}px`;
      });

      return {
        websiteSettings,
        orderLines,
        formattedTotalAmountToBePaid,
        totalQuantity,
        thereAreOrderLinesToBeUpdated,
        recalculating,
        updatingBeforeNextStep,
        showModal,
        selectedOrderLine,
        recalculateCart,
        couponRecalculating,
        burgerClick,
        openDetailModal,
        recalculate,
        goToNextStep,
        getCouponCode,
        isUpdateCoupon,
        couponInput,
        updateCoupon,
        setCouponCode,
        getCouponOfferValid,
        closeModal,
        giftWrapProduct,
        sideInfoItems,
        cartProductsMinHeight,
      };
    },
  });
